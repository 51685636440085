.career-card {
  margin-bottom: 2em;
  .title {
    margin-bottom: 2em;
  }
  .actions {
    margin: 1em;
    justify-content: center;
  }
}
.community-card {
  margin-bottom: 2em;
  .title {
    margin-bottom: 2em;
  }
  .actions {
    margin: 1em;
    justify-content: center;
  }
 
}
.no-card-text {
  text-align: center !important;
}
.loader {
  text-align: center;
}
.filter {
  margin-bottom: 2em;
}