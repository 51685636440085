.main {
    margin: 1em !important;
    width: calc(100% - 2em) !important;
    padding: 10em 10em 10em 10em;
}
.fname-tb {
    margin-right: 0.5em !important;
    margin-bottom: 2em !important;
}
.viewCard {
    margin-bottom: 1em !important;
}

.Filter {
    margin-bottom: 2em;
    align-items: center;
}

.adminPowers {
    margin: 1em !important;
}
.centerMe {
    padding: 0em 5em 0em 5em;
}