.Login {
    margin: 3em;
    text-align: center;

    .alert {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }

    .input-email {
        margin-bottom: 1em;
    }
    .input-password {
        margin-bottom: 1em;
    }
}