.add-button {
  margin: 1em !important;
  width: calc(100% - 2em) !important;
}

.input-title {
  margin-bottom: 1em !important;
}

.type-selector-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .type-selector {
    margin-bottom: 2em !important;
  }
}

