.career-card {
    margin-bottom: 2em;
    .title {
      margin-bottom: 2em;
    }
    .avatar{
      background-color: rgb(234, 170, 0);
  }
    .actions {
      margin: 1em;
      justify-content: center;
    }
    .options {
      margin: 1em;
      justify-content: center;
      align-items: flex-end;
    }
  
  }
  .no-card-text {
    text-align: center !important;
  }
  .loader {
    text-align: center;
  }
  
  