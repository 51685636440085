.main {
    margin: 1em !important;
    width: calc(100% - 2em) !important;
    padding: 10em 10em 10em 10em;
}
.fname-tb {
    margin-right: 0.5em !important;
    margin-bottom: 2em !important;
}
.viewCard {
    margin-bottom: 1em !important;
}

.Filter {
    margin-bottom: 2em;
    align-items: center;
}
.avatar {
    height: 5em;
    width: 5em;

}
.avatarColor {
    background-color: rgb(234, 170, 0) !important;
    width: 100% !important;
    height: 100% !important;

}
