.community-card {
    margin-bottom: 2em;
    .options-menu {
        margin: 1em;
    }
    .avatar{
        background-color: rgb(234, 170, 0);
    }
    .title {
      margin-bottom: 2em;
    }
    .actions {
      // margin: 1em;
      justify-content: center;
      margin-right: 1em;
    }
    .options {
      margin: 1em;
      justify-content: center;
      align-items: flex-end;
    }
   
  
  }
  .no-card-text {
    text-align: center !important;
  }
  .loader {
    text-align: center;
  }
  .justifyMe {
      margin-right: 1em;
  }
