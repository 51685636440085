.View {
    display: flex;
    .toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .content {
        flex-grow: 1;
        padding: 2;
    }
}